var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "visitor-status-icon" }, [
    _vm.status.value === "onfire"
      ? _c("div", { class: "visitor-status-icon--" + _vm.status.value }, [
          _c("img", {
            attrs: { src: require("@/assets/images/vega/onfire.png") },
          }),
        ])
      : _vm.status.value === "hot"
      ? _c("div", { class: "visitor-status-icon--" + _vm.status.value }, [
          _c("img", {
            attrs: { src: require("@/assets/images/vega/hot.png") },
          }),
        ])
      : _vm.status.value === "warm"
      ? _c("div", { class: "visitor-status-icon--" + _vm.status.value }, [
          _c("img", {
            attrs: { src: require("@/assets/images/vega/warm.png") },
          }),
        ])
      : _vm.status.value === "mild"
      ? _c("div", { class: "visitor-status-icon--" + _vm.status.value }, [
          _c("img", {
            attrs: { src: require("@/assets/images/vega/mild.png") },
          }),
        ])
      : _vm.status.value === "cold"
      ? _c("div", { class: "visitor-status-icon--" + _vm.status.value }, [
          _c("img", {
            attrs: { src: require("@/assets/images/vega/cold.png") },
          }),
        ])
      : _vm.status.value === "locked"
      ? _c("div", { class: "visitor-status-icon--" + _vm.status.value }, [
          _c("img", {
            attrs: { src: require("@/assets/images/vega/locked.png") },
          }),
        ])
      : _vm.status.value === "not-enabled"
      ? _c("div", { class: "visitor-status-icon--" + _vm.status.value }, [
          _c("img", {
            attrs: { src: require("@/assets/images/vega/locked.png") },
          }),
        ])
      : _vm.status.value === "frozen"
      ? _c("div", { class: "visitor-status-icon--" + _vm.status.value }, [
          _c("img", {
            attrs: { src: require("@/assets/images/vega/frozen.png") },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }