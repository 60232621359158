<template>
  <div class="visitor-status-icon">
    <div v-if="status.value === 'onfire'" :class="`visitor-status-icon--${status.value}`">
      <img src="@/assets/images/vega/onfire.png" />
    </div>
    <div v-else-if="status.value === 'hot'" :class="`visitor-status-icon--${status.value}`">
      <img src="@/assets/images/vega/hot.png" />
    </div>
    <div v-else-if="status.value === 'warm'" :class="`visitor-status-icon--${status.value}`">
      <img src="@/assets/images/vega/warm.png" />
    </div>
    <div v-else-if="status.value === 'mild'" :class="`visitor-status-icon--${status.value}`">
      <img src="@/assets/images/vega/mild.png" />
    </div>
    <div v-else-if="status.value === 'cold'" :class="`visitor-status-icon--${status.value}`">
      <img src="@/assets/images/vega/cold.png" />
    </div>
    <div v-else-if="status.value === 'locked'" :class="`visitor-status-icon--${status.value}`">
      <img src="@/assets/images/vega/locked.png" />
    </div>
    <div v-else-if="status.value === 'not-enabled'" :class="`visitor-status-icon--${status.value}`">
      <img src="@/assets/images/vega/locked.png" />
    </div>
    <div v-else-if="status.value === 'frozen'" :class="`visitor-status-icon--${status.value}`">
      <img src="@/assets/images/vega/frozen.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VisitorStatusIcon',
  props: {
    score: {
      type: Number,
      default: 0
    },
    online: {
      type: Boolean,
      default: false
    },
    isVegaWidgetEnabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    scoring() {
      const score = this.score > 1 ? this.score / 10 : this.score
      return score ? score * 100 : null
    },
    status() {
      if (!this.isVegaWidgetEnabled || this.isVegaPaused) {
        return {
          value: 'not-enabled',
          text: 'Not enabled'
        }
      }

      if (!this.isVegaActivated || this.vegaWillExceedDailyLimit) {
        return {
          value: 'locked',
          text: 'Locked'
        }
      }

      if (!this.scoring) {
        return {
          value: 'neutral',
          text: 'Neutral'
        }
      }

      if (this.scoring >= 83) {
        return {
          value: 'onfire',
          text: 'On Fire'
        }
      } else if (this.scoring >= 67 && this.scoring < 83) {
        return {
          value: 'hot',
          text: 'Hot'
        }
      } else if (this.scoring >= 50 && this.scoring < 67) {
        return {
          value: 'warm',
          text: 'Warm'
        }
      } else if (this.scoring >= 33 && this.scoring < 50) {
        return {
          value: 'mild',
          text: 'Mild'
        }
      } else if (this.scoring >= 17 && this.scoring < 33) {
        return {
          value: 'cold',
          text: 'Cold'
        }
      } else {
        return {
          value: 'frozen',
          text: 'Frozen'
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.visitor-status-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  box-sizing: border-box;

  img {
    vertical-align: top;
  }

  &--warm {
    height: 16px;
  }
  &--neutral {
    height: 16px;
  }
  &--frozen {
    height: 16px;
  }
  &--mild {
    height: 16px;
  }
  &--onfire {
    height: 16px;
  }
  &--hot {
    height: 16px;
  }
  &--cold {
    height: 16px;
  }
  &--locked {
    height: 16px;
  }
  &--not-enabled {
    height: 16px;
  }
}
</style>
