<template>
  <svg :width="width" :height="height" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M12.2008 8.9998L9.00078 5.7998L5.80078 8.9998" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9 12.1998V5.7998" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'UpgradeIcon',
  props: {
    color: {
      type: String,
      default: '#fff',
      required: false
    },
    width: {
      type: Number,
      default: 18,
      required: false
    },
    height: {
      type: Number,
      default: 18,
      required: false
    }
  }
}
</script>
