import { render, staticRenderFns } from "./VisitorStatusIconWithText.vue?vue&type=template&id=a617c614&scoped=true&"
import script from "./VisitorStatusIconWithText.vue?vue&type=script&lang=js&"
export * from "./VisitorStatusIconWithText.vue?vue&type=script&lang=js&"
import style0 from "./VisitorStatusIconWithText.vue?vue&type=style&index=0&id=a617c614&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a617c614",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/alexbilyk/Projects-Apps/pa/pathadvice-connect/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a617c614')) {
      api.createRecord('a617c614', component.options)
    } else {
      api.reload('a617c614', component.options)
    }
    module.hot.accept("./VisitorStatusIconWithText.vue?vue&type=template&id=a617c614&scoped=true&", function () {
      api.rerender('a617c614', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/vega/VisitorStatusIconWithText.vue"
export default component.exports