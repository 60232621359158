var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "visitor-status", style: _vm.status.style },
    [
      _c("VisitorStatusIcon", {
        attrs: {
          score: _vm.score,
          online: _vm.online,
          isVegaWidgetEnabled: _vm.isVegaWidgetEnabled,
        },
      }),
      _c(
        "div",
        {
          staticClass: "visitor-status--text",
          class: "visitor-status--" + _vm.status.value,
          style: _vm.colorText ? "color: " + _vm.colorText + " !important" : "",
        },
        [_vm._v("\n    " + _vm._s(_vm.status.text) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }